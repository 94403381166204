import React, { useEffect } from 'react';

function PrivacyPolicy() {
  useEffect(() => {
    // Define the Termly script URL
    const scriptUrl = "https://app.termly.io/embed-policy.min.js";
    
    // Check if the script has already been loaded
    if (!document.getElementById('termly-jssdk')) {
      // Create a script element
      const script = document.createElement('script');
      script.id = 'termly-jssdk';
      script.src = scriptUrl;

      // Append the script to the document
      document.getElementsByTagName('head')[0].appendChild(script);
    }
  }, []);

  // Define the styles for the white background
  const divStyle = {
    background: 'white',
    padding: '20px', // Adjust padding as needed
  };

  return (
   // <div name="termly-embed" data-id="1632da50-8258-42fa-80bb-95caf424b2dc" style={divStyle}>
     // {/* Your Termly policy content */}
    //</div>

    <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif' }}>
    <h1 style={{ padding: '20px', fontFamily: 'Arial, sans-serif' }}>Privacy Policy</h1>
    <p style={{ padding: '20px', fontFamily: 'Arial, sans-serif' }}>Last updated: [26th May 2024]</p>
    
    <section>
      <h2 style={{ padding: '20px', fontFamily: 'Arial, sans-serif' }}>Introduction</h2>
      <p style={{ padding: '20px', fontFamily: 'Arial, sans-serif' }}>
        Welcome to Chessverse ! This privacy policy describes how we
        collect, use, and share your personal information when you use our website and services.
      </p>
    </section>

    <section>
      <h2 style={{ padding: '20px', fontFamily: 'Arial, sans-serif' }} >Information We Collect</h2>
      <p style={{ padding: '20px', fontFamily: 'Arial, sans-serif' }}>
        We collect information you provide directly to us when you use our services, or contact us via the contact form. This information 
        may include your name and email address. Cookies are used to store game information.
      </p>
    </section>

    <section>
      <h2 style={{ padding: '20px', fontFamily: 'Arial, sans-serif' }}>How We Use Your Information</h2>
      <p style={{ padding: '20px', fontFamily: 'Arial, sans-serif' }}>
        We use your information to provide, maintain, and improve our services, to communicate 
        with you, and to protect our users. We use cookies to store your game settings and progress.
      </p>
    </section>

    <section>
      <h2 style={{ padding: '20px', fontFamily: 'Arial, sans-serif' }}>Sharing Your Information</h2>
      <p style={{ padding: '20px', fontFamily: 'Arial, sans-serif' }}>
        We may share your information with third parties who provide services on our behalf, 
        with your consent, or as required by law.
      </p>
    </section>

    <section>
      <h2 style={{ padding: '20px', fontFamily: 'Arial, sans-serif' }}>Email Collection</h2>
      <p style={{ padding: '20px', fontFamily: 'Arial, sans-serif' }}>
        If you choose to use the contact form on our website, we may collect your email address and any 
        other information you provide in order to respond to your inquiry.
      </p>
    </section>

    <section>
      <h2 style={{ padding: '20px', fontFamily: 'Arial, sans-serif' }}>COPPA (Children's Online Privacy Protection Act)</h2>
      <p style={{ padding: '20px', fontFamily: 'Arial, sans-serif' }}>
        We comply with the requirements of COPPA (Children's Online Privacy Protection Act). We do
        not knowingly collect personal information from children under the age of 13. If we become 
        aware that we have inadvertently received personal information from a user under the age of 
        13, we will delete such information from our records. If your child decides to provide their email and name 
        via the contact us form, please ensure they seek your consent.
      </p>
      <p style={{ padding: '20px', fontFamily: 'Arial, sans-serif' }}>
        If you are a parent or guardian and you believe that your child under the age of 13 has provided 
        personal information to us, please contact us at chess@thechessverse.com so that we can take 
        appropriate actions.
      </p>
    </section>

    <section>
      <h2 style={{ padding: '20px', fontFamily: 'Arial, sans-serif' }}>Your Rights</h2>
      <p style={{ padding: '20px', fontFamily: 'Arial, sans-serif' }}>
        Depending on your location, you may have certain rights regarding your personal information. 
        These rights may include the right to access, correct, or delete your personal information.
      </p>
    </section>

    <section>
      <h2 style={{ padding: '20px', fontFamily: 'Arial, sans-serif' }}>Changes to This Privacy Policy</h2>
      <p style={{ padding: '20px', fontFamily: 'Arial, sans-serif' }}>
        We may update this privacy policy from time to time. We will notify you of any changes by 
        posting the new privacy policy on this page. You are advised to review this privacy policy 
        periodically for any changes.
      </p>
    </section>

    <section>
      <h2 style={{ padding: '20px', fontFamily: 'Arial, sans-serif' }}>Contact Us</h2>
      <p style={{ padding: '20px', fontFamily: 'Arial, sans-serif' }}>
        If you have any questions about this privacy policy, please contact us at [Your Contact Information].
      </p>
    </section>
  </div>


  );
}

export default PrivacyPolicy;
