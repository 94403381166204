import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useCookies } from "react-cookie";
// import { CookieBanner } from "@keepist/react-gdpr-cookie-banner";
import CookieConsent, { Cookies } from "react-cookie-consent";
import "./index.css";

const CookieConsentComponent = () => {
  // Below commented lines of code can be used for future use.
  // const [cookies, setCookie] = useCookies(["cookieConsent"]);
  // const giveCookieConsent = () => {
  //   setCookie("cookieConsent", true, { path: "/" });
  // };

  return (
    <div>
      {/* <CookieBanner
        message="We use cookies to enhance your user experience. By using our website,
      you agree to our use of cookies."
        onAcceptPreferences={() => {
          // load your preferences trackers here
        }}
        onAcceptStatistics={() => {
          // load your statistics trackers here
        }}
        onAcceptMarketing={() => {
          // load your marketing trackers here
        }}
        policyLink="/privacy-policy"
        acceptSelectionButtonText="Accept Selection"
        showAcceptSelectionButton={true}
        styles={{
          dialog: { backgroundColor: "white", width: "100%", padding: "2vh" },
        }}
      /> */}
      <CookieConsent
        location="bottom"
        buttonText="Accept"
        cookieName="userCookies"
        style={{ background: "#282c34" }}
        buttonStyle={{
          color: "black",
          fontSize: "13px",
          marginRight: "15vh",
          background: "#3127EB",
        }}
        expires={150}
        enableDeclineButton
      >
        <p
          style={{
            fontFamily: "gangofthree",
            fontSize: "large",
          }}
        >
          This website uses cookies to enhance the user experience.Please read
          our 
          <Link to="/privacypolicy" style={{ textDecoration: "underline" }}>
      <a style={{ fontFamily: "gangofthree" , marginLeft: "1vh" }}>
      PRIVACY POLICY
      </a>
    </Link>
          {" "}
          for more details.
        </p>

        

        {/* <span style={{ fontSize: "10px" }}>This bit of text is smaller :O</span> */}
      </CookieConsent>
    </div>
  );
};

export default CookieConsentComponent;
