import React from 'react'
import ReactPlayer from "react-player";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import "./Story.css";
function NewStory() {
  return (
    <div>
    <Header />
    <section className="flat-title-page inner">
        <div className="overlay"></div>
        <div className="themesflat-container">
          <div className="row">
            <div className="col-md-12">
              <div className="page-title-heading mg-bt-12">
                <h1
                  className="heading text-center"
                  style={{ fontFamily: "gangofthree" }}
                >
                  STORY
                </h1>
              </div>
              {/* <div className="breadcrumbs style2">
                <ul>
                  <li>
                    <Link to="/" style={{ fontFamily: "gangofthree" }}>
                      Home
                    </Link>
                  </li>
                  <li>
                    <Link to="#" style={{ fontFamily: "gangofthree" }}>
                      Contact
                    </Link>
                  </li>
                  <li style={{ fontFamily: "gangofthree" }}>Contact 1</li>
                </ul>
              </div> */}
            </div>
          </div>
        </div>
      </section>
    <section className="tf-contact tf-section">
      <div className="themesflat-container">
        <div className="row">
        <div className="col-md-12 videowrapper">
        <ReactPlayer
            url="https://youtu.be/cY6hTXRDlSw"
            // playing={true}
            controls={true}
           height='50%'
           
            // style={{ overflow: "hidden" }}
          />
            </div>
          
        </div>
       
        <div className="row">
        <div className="col-md-12" style={{display:'flex',justifyContent:'center'}}>
        <div  className="storywrapper">
          <p>
            <br></br>
            In the heart of a world not unlike our own, there existed a realm
            known as Chessverse, where chess was more than just a game—it was a
            way of life. Amidst the intricate chessboards and whispered
            strategies, a legendary scientist known only as Mo was a mysterious
            figure, an enigma to all who dwelled within this chess-dominated
            universe. 🌍♟️🔬
            <br></br> <br></br>
            Little was known about Mo, except that he was a revered inventor and
            a chess grandmaster of unmatched prowess. His laboratory, nestled
            atop the grandest peak of Chessverse, was shrouded in secrecy, its
            true purpose hidden from all. 🔍🧪🏞️
            <br></br> <br></br>
            One fateful day, Mo emerged from his hidden lair, his face etched
            with concern and determination. He appeared before the denizens of
            Chessverse, both the knights of wisdom and the rooks of curiosity,
            and with a flourish of his lab coat, he unveiled an astonishing
            creation—a teleporter. With a grave tone, Mo spoke of a dire threat
            looming over their beloved Earth, a threat that could spell
            checkmate for their entire world. "Earth is in grave danger," Mo
            declared, his words hanging heavy in the air. "But I have crafted
            this teleporter, a portal to distant realms where we may find the
            seeds to save our planet from certain doom." 🌎🚀🌌
            <br></br> <br></br>
            The crowd gasped in unison, their wide-eyed wonderment mirrored by
            the gleam in Mo's eye. But as with all great tales, there was a
            twist, a shadow cast over their heroic quest. Mo warned of a rival,
            a formidable and determined figure named Soultan. Soultan was an indomitable and resolute figure, driven by a conviction that Earth had passed the point of no return, deeming it beyond redemption. Instead, he coveted the seeds of creation for a different purpose—to forge Planet Soultan, a sanctuary reserved only for those he deemed worthy. In his distorted vision, this was the sole path to salvation.

Unbeknownst to the world, Soultan harnessed his malevolent powers to summon forth legendary creatures of darkness. Under his sinister control, The Yeti, Ravana, Luison, Typhon, and a multitude of other mythical terror's creatures were being resurrected, ready to take on the role of formidable mini-bosses. Promising a place on Planet Soultan, he lured them from every corner of the universe, weaving a web of deceit and maleficence that stretched far and wide. 😈🌑🌱 <br></br> <br></br>
<br></br>   
            Before embarking on their cosmic mission, Mo set forth a unique
            condition—everyone who hoped to join the quest needed to learn the
            intricate game of chess. For chess was not just a game here; it was
            a source of power and strategy. ♟️🤯♚
            <br></br> <br></br>
            Mo's teleporter whisked his recruits across different parts of
            Earth, regions teetering on the brink of turmoil. There, they
            trained against Mo's chess-playing pets and creatures, each with
            their own peculiar chess styles. From knights that moved in zigzags
            to rooks that had an affinity for the diagonal, these chess
            creatures were as eccentric as they were challenging. Upon defeating
            these whimsical chess pets, Mo awarded each recruit with a
            wristband, its surface adorned with empty slots meant to house
            cosmic gems. These gems, Mo explained, were the key to collecting
            the seeds from distant planets to restore Earth's balance. To obtain
            a gem, one had to emerge victorious over the bosses that guarded
            them on each planet. 💎🌟🐉 <br></br> <br></br>
            But it wasn't just about collecting seeds and gems; there was a
            grander title to be achieved—Grandmaster. Only Grandmasters could
            harness the true power of the seeds. And as luck would have it, the
            existing Grandmasters had just announced the legendary TOURNAMENT.
            The winner of this chess showdown would earn the coveted title of
            Grandmaster. To qualify, one needed to fill their wristband with
            gems from the defeated bosses. But the path to becoming a
            Grandmaster was far from simple. At critical junctures, the
            formidable Soultan and his minions appeared, challenging the recruits and unveiling
            deeper mysteries about both Mo and himself. Soultan, it turned out,
            was once Mo's most brilliant student, considered the prodigy of
            Chessverse. Yet, a lingering resentment brewed between them, an
            unresolved rivalry that had its roots in a past shrouded in chess
            strategy. ♟️🏆🧠 <br></br> <br></br>
            As the tournament unfolded, the recruits faced off against the
            existing four Grandmasters, each more renowned and formidable than
            the last. Their chess mastery knew no bounds, and victory against
            them was a monumental task. But in a twist of fate, the final
            Grandmaster, the reigning champion of Chessverse, revealed a
            shocking secret. They were not the strongest player in the realm.
            There was one they had never bested—a figure who no longer played
            competitively but had chosen to teach instead. The mystery deepened
            as it was unveiled that this teacher was, in fact, Professor Mo
            himself, the very man who had sent them on this cosmic journey. It
            was Mo who had instructed all the Grandmasters, who now stood as
            legends in their own right. As the recruits confronted Professor Mo,
            they couldn't help but question why he had set them on this quest
            when he could have completed it himself. With a knowing smile, Mo
            confessed that age had taken its toll on him, and he had no
            intention of collecting all those seeds himself. Instead, his true
            purpose was to find someone worthy of becoming the guardian of
            Chessverse, the defender of the chess seeds, and the savior of
            Earth. He had found that someone in the brave recruits who had faced
            the trials of the chessverse with courage and determination. 🤔🏅👑{" "}
            <br></br> <br></br>
            In a final, thrilling showdown, the recruits faced off against Mo
            himself in a chess match that defied the laws of time and strategy.
            Mo was, as expected, impossible to beat, for his mastery of the game
            was unparalleled. With the seeds collected and Earth saved, the
            recruits took on their new roles as guardians of Chessverse. They
            worked in harmony with Soultan, who had set aside his rivalry, to
            ensure the seeds were used wisely. And so, the tale of Professor Mo,
            the mysterious chess adventure, and the cosmic revelations came to a
            close. The legacy of chess, mystery, and the uncharted depths of the
            universe lived on, promising endless adventures for generations to
            come. 🌟🚀🌠 <br></br><br></br>
            <h3
                    style={{ color: "white" ,fontFamily: "gangofthree"}}
                  >CHARACTERS</h3>
           Stay tuned for an exclusive glimpse into our captivating characters on our upcoming Instagram channel. Follow us for the coolest character reveals! 🌟📸.
            <br></br><br></br>
            <h3
                    style={{ color: "white" ,fontFamily: "gangofthree"}}
                  >LAUNCH</h3>
            Prepare for an epic gaming experience like no other! 🚀 <br></br>

📚 "Learn and Battle" Mode is on the horizon, and it's arriving soon! Get ready to dive into a world of knowledge, sharpen your skills, and engage in thrilling challenges.
<br></br>
📖 But that's not all! Stay tuned for the upcoming "Story Mode." We're cooking up an immersive narrative that will transport you to a realm of adventure, mystery, and unforgettable characters. It's a journey you won't want to miss, and it's coming your way in the near future.
          </p>
        </div>
            </div>
          
        </div>
      </div>
    </section>
    <Footer />
    
  </div>
  )
}

export default NewStory