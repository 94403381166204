import React, { useState } from "react";
import { Link } from "react-router-dom";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import img1 from "../assets/images/box-item/card-item-10.webp";
import img2 from "../assets/images/box-item/image-box-10.webp";
import img3 from "../assets/images/box-item/image-box-11.webp";
import img4 from "../assets/images/box-item/image-box-21.webp";
import img5 from "../assets/images/box-item/image-box-6.webp";

const Activity01 = () => {
  const [dataBox] = useState([
    {
      img: img1,
      title: "Monica Lucas",
      status: "started following",
      author: "Gayle Hicks",
      time: "At 2:30 PM on 19th June, 2021",
      icon: "icon-1",
    },
    {
      img: img2,
      title: "Wow! That Brain Is Floating",
      status: "10 editions listed by",
      author: "Meowbids",
      time: "At 2:30 PM on 19th June, 2021",
      icon: "icon-2",
    },
    {
      img: img4,
      title: "Our Journey Start",
      status: "started following",
      author: "Gayle Hicks",
      time: "At 2:30 PM on 19th June, 2021",
      icon: "icon-4",
    },
    {
      img: img1,
      title: "Monica Lucas",
      status: "started following",
      author: "Gayle Hicks",
      time: "At 2:30 PM on 19th June, 2021",
      icon: "icon-1",
    },
    {
      img: img4,
      title: "Our Journey Start",
      status: "started following",
      author: "Gayle Hicks",
      time: "At 2:30 PM on 19th June, 2021",
      icon: "icon-4",
    },
  ]);
  const [dataFilter] = useState([
    {
      icon: "icon-fl-sort-filled",
      name: "Listings",
    },
    {
      icon: "icon-fl-heart-filled",
      name: "Like",
    },
    {
      icon: "icon-fl-buy",
      name: "Purchases",
    },
    {
      icon: "icon-fl-discount",
      name: "Sales",
    },
    {
      icon: "icon-fl-logout",
      name: "Transfer",
    },
    {
      icon: "icon-fl-star",
      name: "Burns",
    },
    {
      icon: "icon-fl-credit-card",
      name: "Bids",
    },
    {
      icon: "icon-fl-users-filled",
      name: "Followings",
    },
  ]);

  const [visible, setVisible] = useState(5);
  const showMoreItems = () => {
    setVisible((prevValue) => prevValue + 5);
  };
  return (
    <div>
      <Header />
      <section className="flat-title-page inner">
        <div className="overlay"></div>
        <div className="themesflat-container">
          <div className="row">
            <div className="col-md-12">
              <div className="page-title-heading mg-bt-12">
                <h1 className="heading text-center">Activity 1</h1>
              </div>
              <div className="breadcrumbs style2">
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="#">Activity</Link>
                  </li>
                  <li>Activity 1</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="tf-activity s1 tf-section">
        <div className="themesflat-container">
          <div className="row">
            <div className="col-lg-8 col-md-8 col-12">
              {dataBox.slice(0, visible).map((item, index) => (
                <div className="sc-card-activity style1" key={index}>
                  <div className="content">
                    <div className="media">
                      <img
                        height="auto"
                        width="auto"
                        src={item.img}
                        alt="Chessverse"
                      />
                    </div>
                    <div className="infor">
                      <h3>
                        {" "}
                        <Link to="/item-details-01">{item.title}</Link>
                      </h3>
                      <div className="status">
                        {item.status}{" "}
                        <span className="author">{item.author}</span>
                      </div>
                      <div className="time">{item.time}</div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="col-lg-4 col-md-4 col-12">
              <div id="side-bar" className="side-bar style-2">
                <div className="widget widget-search mgbt-24">
                  <form action="#">
                    <input
                      type="text"
                      placeholder="Enter your word art"
                      required
                    />
                    <button>
                      <i className="icon-fl-search-filled"></i>
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Activity01;
