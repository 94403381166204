import React from 'react';
import ReactPlayer from 'react-player';
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import videofile from '../assets/video/chessvideos.mp4';
import './videos.css';

function Videos() {
  const videos = [
    { url: 'https://www.youtube.com/watch?v=t_f_mB4jjn0', title: 'Pawns', description: 'How pawns move' },
    { url: 'https://youtu.be/j_N5QzlRdio', title: 'Rooks', description: 'How to move rooks' },
    { url: 'https://youtu.be/PMbXtX_n7D4', title: 'Bishop', description: 'How bishops move' },
    { url: 'https://youtu.be/lCRX5zk-5-E', title: 'Queens', description: 'How queens move' },
    { url: 'https://youtu.be/9Vne8e0eg48', title: 'Knights', description: 'How knights move' },
    { url: 'https://youtu.be/eje8foXKHQg', title: 'Kings', description: 'How kings move' },
    { url: 'https://youtu.be/J-4ekmIPpVI', title: 'Check', description: 'What is a check' },
    { url: 'https://youtu.be/q4rbyD-zCFM', title: 'Checkmate', description: 'Checkmate in chess' },
    { url: 'https://youtu.be/BO_LHm-op0Y', title: 'Promotion', description: 'Pawn promotion' },
    { url: 'https://youtu.be/tP4RO50LCkA', title: 'Castling', description: 'Castling in chess' },
    { url: 'https://youtu.be/_tNATUrFQDg', title: 'Foolsmate', description: 'Fastest checkmate in chess' },
    { url: 'https://youtu.be/Mt4OOIyy698', title: 'Buddy mate', description: 'The helper checkmate in chess' },
    { url: 'https://youtu.be/J54ApcgFX5s', title: 'Scholars mate', description: 'The 4 move checkmate' },
    { url: 'https://youtu.be/uWSLoU8X_L4', title: 'Stalemate', description: 'Stalemate in chess' },
    { url: 'https://youtu.be/VtjqCg1auGs', title: 'En Passant', description: 'En Passant' },





    //

  ];

  return (
    <div className="mainDiv">
      <Header />
      <div className="containerWrapper">
        <div className="videos-grid">
          {videos.map((video, index) => (
            <div key={index} className="video-container">
              <ReactPlayer
                url={video.url}
                controls={true}
                width="auto"
                height="auto"
                className="react-player"
                config={{
                  youtube: {
                    playerVars: { showinfo: 1 }
                  }
                }}
              />
              <div className="video-text">
                <p className="video-title">Title: {video.title}</p>
                <p className="video-description">Description: {video.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Videos;
