import img1 from "../images/slider/slide_1.webp";
import img2 from "../images/slider/slide_1.webp";
import imgbg1 from "../images/slider/soultan1.webp";
import imgbg2 from "../images/slider/bg_slide_1.webp";

const heroSliderData = [
  {
    title_1: "Make a move , Save the world",
    title_2: "Learn Chess with Prof. Mo & Nino",
    title_3: "",
    title_4: "",
    description: "",
    img: "https://chessverse.b-cdn.net/soultan1.webp",
    // imgbg: imgbg1,
    class: "left",
  },
  {
    title_1: "Make a move , Save the world ",
    title_2: "Learn Chess with Prof. Mo",
    title_3: "",
    title_4: "",
    description: "",
    img: "https://chessverse.b-cdn.net/slide_1.webp",
    imgbg: "https://chessverse.b-cdn.net/bg_slide_1.webp",
    class: "center",
  },
  {
    title_1: "Make a move , Save the world ",
    title_2: "Learn Chess with Prof. Mo",
    title_3: "",
    title_4: "",
    description: "The one stop place for chess lovers",
    img: "https://chessverse.b-cdn.net/soultan1.webp",
    // imgbg: imgbg2,
    class: "right",
  },
];

export default heroSliderData;
