import React from 'react'
import { useNavigate } from "react-router-dom";
import ReactPlayer from 'react-player'

function Comingsoon() {
    const navigate=useNavigate();
    const handleBack=()=>{
        navigate("/")
    }
  return (
    <div>
    <div style={{ paddingLeft: '20px' , paddingTop: '30px',display:'flex',justifyContent:'center',alignItems:'center'}}><h1> Coming soon... </h1>
      <button onClick={handleBack}>Back</button>
    </div>
    <div style={{ paddingLeft: '20px' , paddingTop: '30px',display:'flex',justifyContent:'center',alignItems:'center'}}>

    <ReactPlayer url='https://youtu.be/cY6hTXRDlSw' playing={true} controls={true} loop={true}/>
    </div>
    </div>
  )
}

export default Comingsoon