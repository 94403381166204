import React, { useRef, useState, useEffect } from "react";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import heroSliderData from "../assets/fake-data/data-slider";
import Slider from "../components/slider/Slider";
import CloseImg from "../assets/images/close.webp";
import planetData from "../assets/fake-data/data-live-auction";
import seedsData from "../assets/fake-data/seeds-data";
import LiveAuction from "../components/layouts/LiveAuction";
import "./Home01.css";
import ReactPlayer from "react-player";

import Carousel from "./Carousel";
import FacebookImage from "../assets/images/icon/facebook.webp";
import GmailImage from "../assets/images/logo/social.webp";
import TwitterImage from "../assets/images/logo/twitter.webp";
import YoutubeImage from "../assets/images/logo/youtube.webp";
import LinkedInImage from "../assets/images/logo/linkedin.webp";
import DiscordImage from "../assets/images/logo/discord.webp";
import InstaImage from "../assets/images/logo/insta.webp";

import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/scss/pagination";

const Home01 = () => {
  const ref = useRef(null);
  const [show, setShow] = useState(false);

  const handleClickAndroid = () => {
    window.location.href =
      "https://play.google.com/store/apps/details?id=com.chessverse.chessverse";
  };

  const handleClickIOS = () => {
    window.location.href = "/coming-soon";
  };

  const handleClose = () => {
    setShow(true);
  };

  useEffect(() => {
    const loadAdScript = () => {
      if (!document.getElementById("adsbygoogle-js")) {
        const script = document.createElement("script");
        script.id = "adsbygoogle-js";
        script.src = "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js";
        script.async = true;
        script.onload = () => {
          console.log("AdsbyGoogle script loaded");
          try {
            (window.adsbygoogle = window.adsbygoogle || []).push({});
            console.log("AdsbyGoogle initialized");
          } catch (e) {
            console.error("AdsbyGoogle error on script load: ", e);
          }
        };
        script.onerror = () => {
          console.error("Failed to load the adsbygoogle script");
        };
        document.head.appendChild(script);
      } else {
        console.log("AdsbyGoogle script already present");
        try {
          (window.adsbygoogle = window.adsbygoogle || []).push({});
          console.log("AdsbyGoogle re-initialized");
        } catch (e) {
          console.error("AdsbyGoogle error on script reuse: ", e);
        }
      }
    };

    loadAdScript();
  }, []);

  return (
    <div className="home-1">
      <Header />
      
      <div className="slidercss">
        <Slider data={heroSliderData} referingvalue={ref} />
      </div>
      
      <div className="bgdivparent">
        <div className="bgdiv">
          <div className="page-title-heading mg-bt-12">
            <h2
              className="heading text-center"
              style={{ fontFamily: "gangofthree" }}
            >
              MAKE A MOVE <br /> SAVE THE WORLD
            </h2>

            <h5
              className="heading text-center"
              style={{ fontFamily: "gangofthree" }}
            >
              PLAY ON DESKTOP BROWSER
            </h5>
          </div>
          
          <div className="image-css" style={{ display: "flex", justifyContent: "space-between" }}>
            <div className="bgdivinside1" style={{ marginBottom: "16px" }}>
              <img
                src="/assets/images/item-background/an.png"
                alt="_blank"
                onClick={handleClickAndroid}
              />
            </div>

            <div
              className="bgdivinside"
              style={{ display: "flex", flexDirection: "column" }}
            >
              <div
                className="bgdivinside2"
                style={{ display: "flex", flexDirection: "column" }}
              >
                <img
                  src="https://chessverse.b-cdn.net/apple-badge-en.svg"
                  alt="_blank"
                  onClick={handleClickIOS}
                />
              </div>
              <span className="text-style" style={{ fontFamily: "gangofthree" }}>
                Coming Soon...
              </span>
            </div>
          </div>

          <div className="divofcarousel">
            <ReactPlayer
              url="https://youtu.be/cY6hTXRDlSw"
              controls={true}
              width="auto"
              height="120%"
            />
          </div>
          
        </div>
      </div>
      <div className="iframeDiv" style={{ position: "relative" }}>
        <div className="iframeContainer" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <iframe
            title="gamesection"
            src="https://d1fl0e1ew6yd6n.cloudfront.net"
            allowFullScreen
            width="960"
            height="650"
            ref={ref}
            style={{ flex: "1 1 auto" }}
          >
          </iframe>
        </div>
      </div>
      <div className="updatesandAdsDiv" style={{ marginTop: "1vh", display: "block" }}>
        <div style={{ margin: "10px 0", padding: "10px", border: "1px solid #ccc", borderRadius: "4px", textAlign: "center" }}>
          <ins
            className="adsbygoogle"
            style={{
              display: "block",
              margin: "0 auto",
              backgroundColor: "#f8f8f8",
              width: "100%",
              height: "100px",  // Set the desired height here
              textAlign: "center",
              overflow: "hidden",
              padding: "10px",
              border: "1px solid #ddd",
              borderRadius: "4px",
            }}
            data-ad-client="ca-pub-3369983366284478"
            data-ad-slot="5389058780"
            data-ad-format="auto"
            data-full-width-responsive="true"
          ></ins>
        </div>
      </div>
      <div className="iframe">
        <Footer />
      </div>
    </div>
  );
};

export default Home01;
