import React, { Suspense, useEffect } from "react";
import "./App.css";
import { Routes, Route,Navigate } from "react-router-dom";
import ReactGA from "react-ga";
import { Helmet } from "react-helmet-async";
import { useCookies } from "react-cookie";
import routes from "./pages/index";
import CookieConsent from "./components/CookieConsent/index";
const TRACKING_ID = "G-24SN503XVZ"; 
ReactGA.initialize(TRACKING_ID);

function App(e) {
  const [cookies] = useCookies(["cookieConsent"]);

  // document.oncontextmenu = function (e) {
  //   alert("Inspect Not Allowed");
  // };
  // document.onkeydown = function (e) {
  //   if (e.keyCode == 123) {
  //     alert("Inspect Not Allowed");
  //     return false;
  //   }
  //   if (e.ctrlKey && e.shiftKey && e.keyCode == "I".charCodeAt(0)) {
  //     alert("Inspect Not Allowed");
  //     return false;
  //   }
  //   if (e.ctrlKey && e.shiftKey && e.keyCode == "J".charCodeAt(0)) {
  //     alert("Inspect Not Allowed");
  //     return false;
  //   }
  //   if (e.ctrlKey && e.keyCode == "U".charCodeAt(0)) {
  //     alert("Inspect Not Allowed");
  //     return false;
  //   }
  //   if (e.ctrlKey && e.keyCode == "C".charCodeAt(0)) {
  //     alert("Inspect Not Allowed");
  //     return false;
  //   }
  // };

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <>
      {
        <Routes>
          {routes.map((data, index) => (
            <Route
              onUpdate={() => window.scrollTo(0, 0)}
              exact={true}
              path={data.path}
              element={data.component}
              key={index}
            />
          ))}
          
      </Routes>
      }
      {!cookies.cookieConsent && <CookieConsent />}
    </>
  );
}

export default App;
