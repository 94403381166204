import React from "react";

function index() {
  return (
    <div className="upddateMain">
      <h6 style={{ fontFamily: "gangofthree" }}>
      &nbsp; &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Our game is currently cruising through the early beta phase, and we're totally pumped to dive into your feedback. We're on the lookout for any wild bugs. <br>
      </br><br></br> &nbsp; &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Let's embark on this adventure together! 🚀😎 .<br>
      </br><br></br>&nbsp; &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Hit us up through our live chat widget or drop by our <a href= "https://discord.gg/FvCaAWhXT4">Discord channel</a>to lay down your feedback groove. Let's make this game even cooler together! 🎮😎.
      </h6>
    </div>
  );
}

export default index;
