import React, { useRef, useState, useEffect } from "react";
import { useMediaQuery } from "@react-hook/media-query";
import { Link, useLocation, useNavigate } from "react-router-dom";
import menus from "../../pages/menu";
import DarkMode from "./DarkMode";
import logoheader from "../../assets/images/newdownloadedlogo.webp";
import logoheader2x from "../../assets/images/newdownloadedlogo.webp";
import logodark from "../../assets/images/newdownloadedlogo.webp";
import logodark2x from "../../assets/images/newdownloadedlogo.webp";
import imgsun from "../../assets/images/icon/sun.webp";
import avt from "../../assets/images/avatar/avt-2.webp";

const Header = () => {
  const matches = useMediaQuery("only screen and (max-width: 600px)");
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const headerRef = useRef(null);
  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  });
  const isSticky = (e) => {
    const header = document.querySelector(".js-header");
    const scrollTop = window.scrollY;
    scrollTop >= 300
      ? header.classList.add("is-fixed")
      : header.classList.remove("is-fixed");
    scrollTop >= 400
      ? header.classList.add("is-small")
      : header.classList.remove("is-small");
  };

  const menuLeft = useRef(null);
  const btnToggle = useRef(null);
  const btnSearch = useRef(null);

  const menuToggle = () => {
    menuLeft.current.classList.toggle("active");
    btnToggle.current.classList.toggle("active");
  };

  const searchBtn = () => {
    btnSearch.current.classList.toggle("active");
  };

  const [activeIndex, setActiveIndex] = useState(null);
  const handleOnClick = (index) => {
    setActiveIndex(index);
  };
  const handleLogout = () => {
    localStorage.removeItem("isLoggedIn");
    navigate("/");
  };

  return (
    <header id="header_main" className="header_1 js-header" ref={headerRef}>
      <div className="themesflat-container">
        <div className="row">
          <div className="col-md-12">
            <div id="site-header-inner">
              
              <div className="wrap-box flex">
                <div id="site-logo" className="clearfix">
                  <div id="site-logo-inner">
                    <Link to="/" rel="home">
                      <img
                        height="auto"
                        width="auto"
                       // style={{ width: "11vh" }}
                        className="logo-dark"
                        id="logo_header"
                        src={"https://chessverse.b-cdn.net/newdownloadedlogo.webp"}
                        srcSet={`${"https://chessverse.b-cdn.net/newdownloadedlogo.webp"}`}
                        alt="gaming"
                      />
                      
                        
                      <img
                        height="auto"
                        width="auto"
                        style={{ width: "20vh" }}
                        className="logo-light"
                        id="logo_header"
                        src={"https://chessverse.b-cdn.net/newdownloadedlogo.webp"}
                        srcSet={`${"https://chessverse.b-cdn.net/newdownloadedlogo.webp"}`}
                        alt="gaming"
                      />
                    </Link>
                  </div>
                
                        

                </div>
                <div
                  className="mobile-button"
                  ref={btnToggle}
                  onClick={menuToggle}
                >
                  <span></span>
                </div>
                <nav
                  id="main-nav"
                  className="main-nav"
                  ref={menuLeft}
                  style={{
                  //  display: pathname == "/" ? "none" : "block",
                    borderRadius: "0px",
                    opacity: "1",
                  }}
                >
                  
                  <ul id="menu-primary-menu" className="menu">
                    
                    {menus.map((data, index) => (
                      <li
                        key={index}
                        onClick={() => handleOnClick(index)}

                        // className={`menu-item ${
                        //   data.namesub ? "menu-item-has-children" : ""
                        // } ${activeIndex === index ? "active" : ""} `}
                      >
                        <Link
                          to={data.links}
                          style={{
                            fontFamily: "gangofthree",
                            display:
                              matches && data.name == "Games"
                                ? "none"
                                : "block",
                          }}
                        >
                          {data.name}
                        </Link>
                        {/* {data.namesub && (
                          <ul className="sub-menu">
                            {data.namesub.map((submenu) => (
                              <li
                                key={submenu.id}
                                className={
                                  pathname === submenu.links
                                    ? "menu-item current-item"
                                    : "menu-item"
                                }
                              >
                                <Link to={submenu.links}>{submenu.sub}</Link>
                              </li>
                            ))}
                          </ul>
                        )} */}
                      </li>
                    ))}
                  </ul>
                </nav>
                <div className="flat-search-btn flex">
                  {/* <div className="header-search flat-show-search" id="s1">
                    <Link
                      to="#"
                      className="show-search header-search-trigger"
                      onClick={searchBtn}
                    >
                      <i className="far fa-search"></i>
                    </Link>
                    <div className="top-search" ref={btnSearch}>
                      <form
                        action="#"
                        method="get"
                        role="search"
                        className="search-form"
                      >
                        <input
                          type="search"
                          id="s"
                          className="search-field"
                          placeholder="Search..."
                          name="s"
                          title="Search for"
                          required=""
                        />
                        <button
                          className="search search-submit"
                          type="submit"
                          title="Search"
                        >
                          <i className="icon-fl-search-filled"></i>
                        </button>
                      </form>
                    </div>
                  </div> 
                  <button
                    style={{
                      backgroundColor: "black",
                      display: pathname == "/" ? "none" : "block",
                      fontFamily: "gangofthree",
                    }}
                    onClick={handleLogout}
                  >
                    Logout
                  </button>*/}

                  <div className="admin_active" id="header_admin">
                    <div className="header_avatar">
                      <div className="price">
                        <span>
                         
                        </span>
                      </div>
                      <img
                        height="auto"
                        width="auto"
                        className="avatar"
                        src={avt}
                        alt="avatar"
                      />
                      <div className="avatar_popup mt-20">
                        <div className="d-flex align-items-center copy-text justify-content-between">
                          <span> 13b9ebda035r178... </span>
                          <Link to="/" className="ml-2">
                            <i className="fal fa-copy"></i>
                          </Link>
                        </div>
                        <div className="d-flex align-items-center mt-10">
                          <img
                            height="auto"
                            width="auto"
                            className="coin"
                            src={imgsun}
                            alt="/"
                          />
                          <div className="info ml-10">
                            <p className="text-sm font-book text-gray-400">
                              Balance
                            </p>
                            <p className="w-full text-sm font-bold text-green-500">
                              
                            </p>
                          </div>
                        </div>
                        <div className="hr"></div>
                        <div className="links mt-20">
                          <Link to="#">
                            <i className="fab fa-accusoft"></i>{" "}
                            <span> My items</span>
                          </Link>
                          <a className="mt-10" href="/edit-profile">
                            <i className="fas fa-pencil-alt"></i>{" "}
                            <span> Edit Profile</span>
                          </a>
                          <a className="mt-10" href="/login" id="logout">
                            <i className="fal fa-sign-out"></i>{" "}
                            <span> Logout</span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <DarkMode />
    </header>
  );
};

export default Header;
