const menus = [
  {
    id: 1,
    name: "Home",
    links: "/home",
  },
  {
    id: 13,
    name: "Story",
    links: "/story",
  },

  {
    id: 8,
    name: "Videos",
    links: "/videos",
  },
  /*{
    id: 9,
    name: "Games",
    links: "/games-play",
  },*/
  {
    id: 10,
    name: "Puzzles",
    links: "/puzzles",
  },
  {
    id: 11,
    name: "Contact Us",
    links: "/contact-01",
    // namesub: [
    //   {
    //     id: 1,
    //     sub: "Contact us",
    //     links: "/contact-01",
    //   },
    // ],
  },
  {
    id: 12,
    name: "About Us",
    links: "/about-us",
  },
  
];

export default menus;
