import React from "react";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import profmoimage from "../../src/assets/images/monino1.webp";
import "./Aboutus.css";

function Aboutus() {
  return (
    <div>
      <Header />
      <section className="flat-title-page inner">
        <div className="overlay"></div>
        <div className="themesflat-container">
          <div className="row">
            <div className="col-md-12">
              <div className="page-title-heading mg-bt-12">
                <h1
                  className="heading text-center"
                  style={{ fontFamily: "gangofthree" }}
                >
                  About Us
                </h1>
              </div>
         
            </div>
          </div>
        </div>
      </section>
      <section className="tf-contact tf-section">
        <div className="themesflat-container">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-12">
              <div className="box-feature-contact">
                <img height="auto" width="auto" src={"https://chessverse.b-cdn.net/monino1.webp"} alt="Chessverse" />
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-12 textSection">
              <h2
                className="tf-title-heading style-2 mg-bt-12"
                style={{ fontFamily: "peppermint" }}
              >
                WHO WE ARE
              </h2>
              
              <p
                className="sub-title style-3"
                style={{ fontFamily: 'peppermint' }}
              >
               Hey there! We're a small crew right out of Edinburgh, and we're stoked to introduce you to Chessverse, our latest gaming creation. 🎮

We've crafted Chessverse with one goal in mind: to make chess super accessible and incredibly fun for everyone. 🤩

Why chess, you ask? Well, it's not just a game; it's a brain-boosting, creativity-sparking, decision-making powerhouse! We believe everyone should have a shot at reaping those benefits. 🧠💡

Now, here's the scoop: Chessverse is still in its early stages, but we're not stopping there! We're cooking up some awesome new features to make your chess experience even more epic. 🚀

Got thoughts, feedback, or stumbled upon a pesky bug? No problemo! Hit us up at chess@thechessverse.com. We're all ears and ready to chat. 📩
<br></br>
Let's make chess cool, together ! 😎👑 #ChessverseAdventures
              </p>
              
              
            </div>
          </div>
        </div>
       
      </section>
      <Footer />
    </div>
  );
}

export default Aboutus;