import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Navigation, Scrollbar, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/scss/pagination";
import shape1 from "../../assets/images/backgroup-secsion/bg-gradient1.webp";
import shape2 from "../../assets/images/backgroup-secsion/bg-gradient2.webp";
import shape3 from "../../assets/images/backgroup-secsion/bg-gradient3.webp";
//import imgbg from "../../assets/images/backgroup-secsion/img_bg_page_title.webp";
//import profMo from "../../assets/images/chars/mo.webp";
import profMo from "../../assets/images/monino1.webp";

import "./Slider.css";

const Slider = (props) => {
  const { referingvalue } = props;
  const data = props.data;
  return (
    <div className="sliderDisplay mainslider">
      <Swiper
        modules={[Navigation, Scrollbar, A11y]}
        spaceBetween={0}
        slidesPerView={1}
        navigation
        scrollbar={{ draggable: false }}
        allowSlideNext={false}
      >
        {data.map((item, index) => (
          <SwiperSlide key={index} className={item.class}>
            <SliderItem item={item} referingvalue={referingvalue} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

Slider.propTypes = {
  data: PropTypes.array.isRequired,
  control: PropTypes.bool,
  auto: PropTypes.bool,
  timeOut: PropTypes.number,
};
const SliderItem = (props) => {
  const { referingvalue } = props;
  const handleClick = () => {
    referingvalue.current?.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
  };
  return (
    <div
      className="flat-title-page"
      /* style={{ backgroundImage: `url(${imgbg})` }}  */
    >
      <img
        height="auto"
        width="auto"
        className="bgr-gradient gradient1"
        src={shape1}
        alt="Chessverse"
      />
      <img
        height="auto"
        width="auto"
        className="bgr-gradient gradient2"
        src={shape2}
        alt="Chessverse"
      />
      <img
        height="auto"
        width="auto"
        className="bgr-gradient gradient3"
        src={shape3}
        alt="Chessverse"
      />
      <div className="shape item-w-16"></div>
      <div className="shape item-w-22"></div>
      <div className="shape item-w-32"></div>
      <div className="shape item-w-48"></div>
      <div className="shape style2 item-w-51"></div>
      <div className="shape style2 item-w-51 position2"></div>
      <div className="shape item-w-68"></div>
      <div className="overlay"></div>
      <div className="swiper-container mainslider home">
        <div className="swiper-wrapper">
          <div className="swiper-slide">
            <div className="slider-item">
              <div className="themesflat-container ">
                <div className="wrap-heading flat-slider flex">
                  <div className="content">
                    <h3
                      className="heading"
                      style={{
                        fontFamily: "gangofthree",
                        fontSize: "x-large",
                      }}
                    >
                      {props.item.title_1}
                    </h3>

                    <h2 className="heading mb-style">
                      <span
                        className="tf-text s1"
                        style={{
                          fontFamily: "gangofthree",
                          fontSize: "x-large",
                        }}
                      >
                        {props.item.title_2}
                      </span>
                      
                      
                      <div>
                        <img
                          className="profmoimage"
                          // height="200px"
                          // width="150px"
                          src={"https://chessverse.b-cdn.net/monino1.webp"}
                          alt="Prof Mo"
                        />
                      </div>
                    </h2>

                    <h1 className="heading">{props.item.title_3}</h1>
                    <p className="sub-heading">{props.item.description}</p>

                    <br />
                    {/* <button
                      onClick={handleClick}
                      className="playBtn"
                      style={{
                        left: "20vh",
                      }}
                    ></button> */}

                    {/* <div
                      className="flat-bt-slider flex style2"
                      style={{
                        display: props.item.class == "left" ? "none" : "block",
                      }}
                    >
                      <Link
                        to="/explore-01"
                        className="sc-button header-slider style style-1 rocket fl-button pri-1"
                      >
                        <span style={{ fontFamily: "gangofthree" }}>
                          Explore
                        </span>
                      </Link>
                      <Link
                        to="/create-item"
                        className="sc-button header-slider style style-1 note fl-button pri-1"
                      >
                        <span style={{ fontFamily: "gangofthree" }}>
                          Create
                        </span>
                      </Link>
                    </div> */}
                  </div>
                 
                  <button
                    onClick={handleClick}
                    className="playBtn"
                    aria-label="play"
                    // style={{
                    //   display: props.item.class == "center" ? "none" : "block",
                    // }}
                  ></button>
                  
                  <div className="image">
                    {/* <img height="auto" width="auto" className="img-bg" src={props.item.imgbg} alt="Chessverse" /> */}
                    <img
                      height="auto"
                      width="auto"
                      src={props.item.img}
                      alt="Chessverse"
                      style={{ width: "50vh" }}
                    />
                  </div>
                  
                  
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </div>
  );
};
export default Slider;
