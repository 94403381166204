import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import ScrollToTop from "./ScrollToTop";
import { Helmet, HelmetProvider } from "react-helmet-async";

const helmetContext = {};

if (window.self === window.top) {
  ReactDOM.render(
    <BrowserRouter>
      <ScrollToTop />
      <HelmetProvider context={helmetContext}>
        <App />

        {/* <div style={{ paddingLeft: "20px", paddingTop: "30px" }}>
          <h1> Website coming soon..... </h1>
        </div> */}
      </HelmetProvider>
    </BrowserRouter>,
    document.getElementById("root")
  );
}
