import React from "react";
import ShowMoreText from "react-show-more-text";
import "./index.css";

function Index() {
  let executeOnClick = (isExpanded) => {
    console.log(isExpanded);
  };
  return (
    <div className="showMoreMainDiv">
      <ShowMoreText
        /* Default options */
        lines={3}
        more="Show more"
        less="Show less"
        className="content-css"
        anchorClass="show-more-less-clickable"
        onClick={executeOnClick}
        expanded={false}
        width={1500}
        truncatedEndingComponent={"... "}
      >
        <p className="para" style={{ fontFamily: "gangofthree" }}>
          {" "}
          Lorem ipsum dolor sit amet, consecteturyahoo.com adipiscing elit, sed
          do eiusmod tempor incididunt www.google.bg ut labore et dolore magna
          amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
          labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
          exercitation ullamco laboris nisi ut aliquip ex Lorem ipsum dolor sit
          amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
          labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
          exercitation ullamco laboris nisi ut aliquip ex Lorem ipsum dolor sit
          amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
          labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
          exercitation. ut labore et dolore magna amet, consectetur adipiscing
          elit, sed do eiusmod tempor incididunt ut labore et dolore magna
          aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
          laboris nisi ut aliquip ex Lorem ipsum dolor sit amet, consectetur
          adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
          magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation
          ullamco laboris nisi ut aliquip ex Lorem ipsum dolor sit amet,
          consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
          labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
          exercitation.
        </p>
      </ShowMoreText>
    </div>
  );
}

export default Index;
