import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Pagination from "../components/Pagination/Index.js";
import ShowMore from "../components/ShowMore/Index.js";
import Header from "../components/header/Header";
import { Select, Space } from "antd";
import HoverVideoPlayer from "react-hover-video-player";
import video from "../../src/assets/images/dancevideo.mp4";
import "./GamesPlay.css";
import imgArr from "./GamesData.js";
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

function GamesPlay() {
  const navigate = useNavigate();
  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };

  const handleClick = (item) => {
    console.log("Handle Click ", item);
    navigate("/game", { state: { frameLink: item.frameLink } });
  };
  const [page, setPage] = useState(0);
  const [filterData, setFilterData] = useState();
  const n = 25;
  useEffect(() => {
    setFilterData(
      imgArr.filter((item, index) => {
        return (index >= page * n) & (index < (page + 1) * n);
      })
    );
  }, [page]);
  return (
    <div>
      <Header />
      <div
        style={{
          position: "absolute",
          top: "15vh",
          width: "auto",
          padding: "2vh",
        }}
      >
        <div>
          <a
            href="#"
            style={{
              cursor: "pointer",
              fontSize: "x-large",
              color: "#A48EFF",
              fontFamily: "gangofthree",
            }}
          >
            Multiplayer
          </a>
          <h6
            style={{
              color: "white",
              fontSize: "x-large",
              fontFamily: "gangofthree",
              marginTop: "2vh",
            }}
          >
            MMO GAMES
          </h6>
        </div>

        <div
          style={{
            display: "flex",
            marginTop: "2vh",
            flexWrap: "wrap",
            justifyContent: "flex-start",
            gap: "1vh",
          }}
        >
          <div
            style={{
              display: "flex",
              marginTop: "2vh",
              justifyContent: "space-between",
              width: "94vw",
            }}
          >
            {" "}
            <p style={{ fontFamily: "gangofthree" }}>
              Join with millions of other players in a range of different worlds
              and scenarios with this collection of top free MMO games!
            </p>
            <Space wrap>
              <Select
                defaultValue="Top Games"
                style={{
                  width: 120,
                  fontFamily: "gangofthree",
                }}
                onChange={handleChange}
                options={[
                  {
                    value: "Top Games",
                    label: "Top Games",
                    disabled: true,
                  },
                  {
                    value: "New Games",
                    label: "New Games",
                  },
                  {
                    value: "Most Played",
                    label: "Most Played",
                  },
                ]}
              />
            </Space>
          </div>

          {filterData &&
            filterData.map((item) => {
              return (
                <>
                  <HoverVideoPlayer
                    videoSrc={video}
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => handleClick(item)}
                    videoStyle={{
                      borderRadius: "10px",
                      width: "34vh",
                      marginBottom: "0",
                    }}
                    pausedOverlay={
                      <img
                        height="auto"
                        width="auto"
                        src={item.img}
                        alt=""
                        style={{
                          // Make the image expand to cover the video's dimensions
                          width: "100%",
                          height: "19vh",
                          borderRadius: "10px",
                          objectFit: "cover",
                          border: "1px solid blue",
                        }}
                      />
                    }
                    loadingOverlay={
                      <div className="loading-overlay">
                        <div className="loading-spinner" />
                      </div>
                    }
                  />
                </>
              );
            })}
        </div>
        <div className="paginationDiv">
          <Pagination data={imgArr} n={n} settingpage={setPage} />
        </div>
        <ShowMore />
      </div>
    </div>
  );
}

export default GamesPlay;
