import { useState, useEffect } from "react";
import moment from "moment";
import CorrecIcon from "../assets/images/correct.webp";
import HiIcon from "../assets/images/hi.webp";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import { Select, Space, Button, Modal } from "antd";
import { Puzzle } from "react-chess-puzzle";
import Chess from "chess.js";

// import { Chessboard } from "react-chessboard";

import { getPuzzles, selectPuzzles } from ".././services/puzzles";
import "./Puzzles.css";
const puzzle = {
  //4kb1r/p2r1ppp/4qn2/1B2p1B1/4P3/1Q6/PPP2PPP/2KR4 w k - 0 1 // "Bxd7+", "Nxd7", "Qb8+", "Nxb8", "Rd8#"
  //1k6/1pp2Qpp/p7/8/6P1/7q/PP1rrR2/R5K1 w - - 2 33 //f7f8
  fen: "1k6/1pp2Qpp/p7/8/6P1/7q/PP1rrR2/R5K1 w - - 2 33",
  moves: ["f7f8"],
};
const puzzles = [
  {
    fen: "Q1b2r1k/p2np2p/5bp1/q7/5P2/4B3/PPP3PP/2KR1B1R w - - 1 17",
    moves: ["d1d7", "a5e1", "d7d1", "e1e3", "c1b1", "e3b6"],
  },
];
const options = [
  {
    name: "White",
    value: "white",
  },
  {
    name: "Black",
    value: "black",
  },
];

const newThemesOptions = [
  {
    name: "Fork",
    value: "Fork",
  },
  {
    name: "Pin",
    value: "Pin",
  },
  {
    name: "Skewer",
    value: "Skewer",
  },
  {
    name: "Fork",
    value: "Fork",
  },
  {
    name: "Zugzwang",
    value: "Zugzwang",
  },
  {
    name: "Promotion",
    value: "Promotion",
  },
  {
    name: "Checkmate",
    value: "Checkmate",
  },
  {
    name: "MateIn1",
    value: "MateIn1",
  },
  {
    name: "MateIn2",
    value: "MateIn2",
  },
  {
    name: "MateIn3",
    value: "MateIn3",
  },
  {
    name: "MateIn4",
    value: "MateIn4",
  },
  {
    name: "MateIn5",
    value: "MateIn5",
  },
  {
    name: "Discoveredattack",
    value: "Discoveredattack",
  },
  {
    name: "Deflection",
    value: "Deflection",
  },
  {
    name: "Castling",
    value: "Castling",
  },
  {
    name: "Backrank",
    value: "Backrank",
  },
  {
    name: "Clearence",
    value: "Clearence",
  },
  {
    name: "Enpassant",
    value: "Enpassant",
  },
  {
    name: "Anastasiamate",
    value: "Anastasiamate",
  },
  {
    name: "Arabianmate",
    value: "Arabianmate",
  },
  {
    name: "Attraction",
    value: "Attraction",
  },
  {
    name: "BishopEndgame",
    value: "BishopEndgame",
  },
  {
    name: "BodenMate",
    value: "BodenMate",
  },
  {
    name: "DovetailMate",
    value: "DovetailMate",
  },
  {
    name: "HangingPiece",
    value: "HangingPiece",
  },
  {
    name: "HookMate",
    value: "HookMate",
  },
  {
    name: "Interference",
    value: "Interference",
  },
  {
    name: "Intermezzo",
    value: "Intermezzo",
  },
  {
    name: "KnightEndgame",
    value: "KnightEndgame",
  },

  {
    name: "PawnEndgame",
    value: "PawnEndgame",
  },
  {
    name: "QueenEndgame",
    value: "QueenEndgame",
  },
  {
    name: "QuietMove",
    value: "QuietMove",
  },
  {
    name: "RookEndgame",
    value: "RookEndgame",
  },
  {
    name: "Sacrifice",
    value: "Sacrifice",
  },
  {
    name: "SmotheredMate",
    value: "SmotheredMate",
  },
  {
    name: "TrappedPiece",
    value: "TrappedPiece",
  },
  {
    name: "UnderPromotion",
    value: "UnderPromotion",
  },
  {
    name: "DefensiveMove",
    value: "DefensiveMove",
  },
  {
    name: "Crushing",
    value: "Crushing",
  },
  {
    name: "CapturingDefender",
    value: "CapturingDefender",
  },
  {
    name: "Middlegame",
    value: "Middlegame",
  },
  {
    name: "Endgame",
    value: "Endgame",
  },
  {
    name: "Opening",
    value: "Opening",
  },
];

const difficultyOptions = [
  {
    name: "Very Easy",
    value: "Veryeasy",
  },
  {
    name: "Easy",
    value: "Easy",
  },
  {
    name: "Medium",
    value: "Medium",
  },
  {
    name: "Difficult",
    value: "Difficult",
  },
  {
    name: "Hard",
    value: "Hard",
  },
  {
    name: "Extreme",
    value: "Extreme",
  },
];

function Puzzles() {
  const [whoToPlay, setWhoToPlay] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [modal1Open, setModal1Open] = useState(false);
  const [turn, setTurn] = useState(0);
  const [options, setOptions] = useState(false);
  const [selectedItem, setSelectedItem] = useState("");
  const [selectedRating, setSelectedRating] = useState("");
  const [puzzlesArray, setPuzzlesArray] = useState([]);
  const [puzzleList, setPuzzleList] = useState([]);
  const [puzzleIndex, setPuzzleIndex] = useState(0);
  const [game, setGame] = useState(new Chess());
  const [oreintation, setOrientation] = useState("white");
  const [puzzlesOptions, setPuzzlesOptions] = useState({ fen: "", moves: "" });
  const [showNewPuzzle, setShowNewPuzzle] = useState(false);
  const [loading, setLoading] = useState(false);

  function makeAMove(move) {
    const gameCopy = { ...game };
    const result = gameCopy.move(move);
    setGame(gameCopy);
    return result; // null if the move was illegal, the move object if the move was legal
  }

  function makeRandomMove() {
    console.log("hii", puzzlesOptions.moves[0]);

    // const possibleMoves = game.moves();
    // if (game.game_over() || game.in_draw() || possibleMoves.length === 0) {
    //   setGame(new Chess());
    // }
    // // exit if the game is over
    // const randomIndex = Math.floor(Math.random() * possibleMoves.length);
    makeAMove(puzzlesOptions.moves[0]);
  }

  function onDrop(sourceSquare, targetSquare) {
    const move = makeAMove({
      from: sourceSquare,
      to: targetSquare,
      promotion: "q", // always promote to a queen for example simplicity
    });

    // illegal move
    if (move === null) return false;
    setTimeout(makeRandomMove, 200);
    return true;
  }
  const handleReset = () => {
    console.log(game);
    setGame(new Chess());
  };
  const handlechange = (e) => {
    console.log(e.target.value);
    setOrientation(e.target.value);
    console.log(oreintation);
  };

  useEffect(() => {
    if (game.game_over() || game.in_checkmate()) {
      //   console.log(game.game_over());
      //   console.log(game.in_checkmate());
      setGame(new Chess());
    }
    console.log("Who To Play", puzzle.fen.split(" ")[1]);
    if (puzzle.fen.split(" ")[1] == "w") {
      setWhoToPlay("White To Play");
      setModal1Open(true);
    } else {
      setWhoToPlay("Black To Play");
      setModal1Open(true);
    }
  }, []);
  const pieces = [
    "wP",
    "wN",
    "wB",
    "wR",
    "wQ",
    "wK",
    "bP",
    "bN",
    "bB",
    "bR",
    "bQ",
    "bK",
  ];
  const customPieces = () => {
    const returnPieces = {};
    pieces.map((p) => {
      returnPieces[p] = ({ squareWidth }) => (
        <div
          style={{
            width: squareWidth,
            height: squareWidth,
            backgroundImage: `url('/assets/media/${p}.png')`,
            backgroundSize: "100%",
          }}
        />
      );
      return null;
    });
    return returnPieces;
  };
  const handleTurn = async (e) => {
    e.preventDefault();
    if (turn < 9) {
      setTurn(turn + 1);
    } else {
      setTurn(0);
    }
    let data = await getPuzzles(turn);

    if (data.length > 0) {
      setPuzzlesArray(data);
      // console.log("DATA ", puzzlesArray);
      setOptions(true);
    }
  };
  const handleSelectChange = (event) => {
    setSelectedItem(event.target.value);
    // console.log("SELECTED ITEM ", selectedItem);
  };

  const handleSelectRatingChange = (event) => {
    setSelectedRating(event.target.value);
    // console.log("SELECTED RATING ", selectedRating);
  };
  const handleMakeAIMove = () => {
    const newFen = makeAIMove();
    console.log("New fen", newFen);
  };
  useEffect(() => {
    makeRandomMove();
    if (showNewPuzzle == false && puzzlesOptions.moves.length > 0) {
      console.log(puzzlesOptions);
      setShowNewPuzzle(true);
    }
  }, [puzzlesOptions?.fen]);

  const handleGetPuzzle = async (e) => {
    console.log("handle Get Puzzle ", turn, selectedItem, selectedRating);
    setShowNewPuzzle(false);
    let puzzleData = await selectPuzzles(turn, selectedItem, selectedRating);
    console.log(puzzleData);
    setPuzzleList(puzzleData?.d);
    // if (puzzleData?.data) {
    //   setPuzzlesOptions({
    //     fen: puzzleData?.data?.FEN ? puzzleData?.data?.FEN : "",
    //     moves: puzzleData?.data?.Moves
    //       ? puzzleData?.data?.Moves.split(" ")
    //       : "",
    //   });
    // setShowNewPuzzle(true);
    // }
  };

  const handleNextSet = async () => {
    if (turn < 9) {
      setTurn(turn + 1);
    } else {
      setTurn(0);
    }
    let puzzleData = await selectPuzzles(turn, selectedItem, selectedRating);
    console.log(puzzleData);
    setPuzzleList(puzzleData?.d);
    // setOptions(false);
  };

  const handlePuzzleItemClick = (item) => {
    setPuzzlesOptions({
      fen: item.FEN,
      moves: item.Moves.split(" "),
    });
    setShowNewPuzzle(false);
  };

  return (
    <div>
      <Header />
      <section className="flat-title-page inner">
        <div className="overlay"></div>
        <div className="themesflat-container">
          <div className="row">
            <div className="col-md-12">
              <div className="page-title-heading mg-bt-12">
                <div>
                  <h3
                    style={{ color: "white" ,fontFamily: "gangofthree"}}
                  >{`Brain Teaser Bonanza of the Day: Emoji Puzzlement! 🤯🧩😄, ${moment().format(
                    "LL"
                    
                  )}`}</h3>
                  <h3 style={{ color: "white",fontFamily: "gangofthree" }}>
                    {
                      "HINT: The black king has a soft spot for moustaches. 😎👑🧔. Checkmate in one. ♚➡️1🏁."
                    }
                  </h3>
                </div>
              </div>
              {/* <div className="breadcrumbs style2">
                <ul>
                  <li>
                    <Link to="/" style={{ fontFamily: "gangofthree" }}>
                      Home
                    </Link>
                  </li>
                  <li>
                    <Link to="#" style={{ fontFamily: "gangofthree" }}>
                      Contact
                    </Link>
                  </li>
                  <li style={{ fontFamily: "gangofthree" }}>Contact 1</li>
                </ul>
              </div> */}
            </div>
          </div>
        </div>
      </section>
      <section className="tf-contact tf-section">
        <div className="themesflat-container">
          <div className="row">
            <div>
              <div className="puzzleMainContainer">
                <div
                  className="puzzlewarapper"
                  style={{ display: showNewPuzzle ? "none" : "block" }}
                >
                  <Puzzle.Root
                    puzzle={puzzle}
                    onSolve={() => {
                      console.log("It is solved");
                      setModalOpen(true);
                    }}
                    onFail={() => {
                      console.log("It is failed");
                    }}
                  >
                    <Puzzle.Board
                      onDrop={() => {
                        console.log("It is dropped..");
                      }}
                      customDarkSquareStyle={{ backgroundColor: "Black" }}
                      customLightSquareStyle={{ backgroundColor: "white" }}
                      customPieces={customPieces()}
                    />
                    <div className="btnDiv">
                      <Puzzle.Reset asChild>
                        <button>restart</button>
                      </Puzzle.Reset>
                      {/* <Puzzle.Reset
                  asChild
                  puzzle={puzzles[(puzzleIndex + 1) % puzzles.length]}
                  onReset={() => {
                    setPuzzleIndex((puzzleIndex + 1) % puzzles.length);
                  }}
                >
                  <button style={{ fontFamily: "Gang of Three, sans-serif" }}>
                    next
                  </button>
                </Puzzle.Reset> */}
                    </div>
                    <div className="btnDiv">
                      <Puzzle.Hint>hint</Puzzle.Hint>
                    </div>
                  </Puzzle.Root>
                </div>
                <div
                  style={{
                    color: "white",
                    display: loading ? "block" : "none",
                  }}
                >
                  Loading
                </div>
                {puzzlesOptions && showNewPuzzle ? (
                  <div
                    className="puzzlewarapper"
                    style={{
                      display: showNewPuzzle ? "none" : "none",
                    }}
                  >
                    <Puzzle.Root
                      puzzle={puzzlesOptions}
                      onSolve={() => {
                        console.log("It is solved");
                        setModalOpen(true);
                      }}
                      onFail={() => {
                        console.log("It is failed");
                      }}
                    >
                      <Puzzle.Board
                        customDarkSquareStyle={{ backgroundColor: "green" }}

                        // customPieces={customPieces()}
                      />
                      <div className="btnDiv">
                        <Puzzle.Reset asChild>
                          <button>restart</button>
                        </Puzzle.Reset>
                        {/* <Puzzle.Reset
                    asChild
                    puzzle={puzzles[(puzzleIndex + 1) % puzzles.length]}
                    onReset={() => {
                      setPuzzleIndex((puzzleIndex + 1) % puzzles.length);
                    }}
                  >
                    <button style={{ fontFamily: "Gang of Three, sans-serif" }}>
                      next
                    </button>
                  </Puzzle.Reset> */}
                      </div>
                      <Puzzle.Hint className="custom-hint">hint</Puzzle.Hint>
                      <div className="btnDiv"></div>
                    </Puzzle.Root>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div style={{ display: "none" }}>
                <div className="optionsclass">
                  {" "}
                  <select
                    value={selectedItem}
                    onChange={handleSelectChange}
                    style={{ color: "black" }}
                  >
                    {" "}
                    <option value="">--Please choose an themes--</option>
                    {/* {puzzlesArray.map((item, index) => (
                <>
                  <option key={item.id} value={item.puzzle.Themes}>
                    {item.puzzle.Themes}
                  </option>
                </>
              ))} */}
                    {newThemesOptions.map((item, index) => (
                      <>
                        <option key={index} value={item.value}>
                          {item.value}
                        </option>
                      </>
                    ))}
                  </select>
                  <select
                    value={selectedRating}
                    onChange={handleSelectRatingChange}
                    style={{ color: "black", width: "auto" }}
                  >
                    {" "}
                    <option value="">--Please choose a difficult--</option>
                    {difficultyOptions.map((item, index) => (
                      <>
                        <option key={index} value={item.value}>
                          {item.value}
                        </option>
                      </>
                    ))}
                  </select>
                  <div>
                    <button onClick={handleGetPuzzle}>Get Puzzle</button>
                    <button onClick={handleNextSet}>Next Set</button>
                  </div>
                  {/* <button
              className="getOptionsBtn"
              style={{ display: options ? "none" : "block" }}
              onClick={handleTurn}
            >
              Get Options
            </button> */}
                  <div style={{ color: "white" }}>
                    {puzzleList &&
                      puzzleList.map((item, index) => {
                        return (
                          <ol>
                            <li
                              key={index}
                              style={{
                                padding: "1vh",
                                fontSize: "x-large",
                                marginBottom: "1vh",
                                border: "1px solid white",
                                borderRadius: "5px",
                                cursor: "pointer",
                              }}
                              className="hoverlist"
                              onClick={() => {
                                handlePuzzleItemClick(item);
                              }}
                            >
                              {item.Themes}
                            </li>
                          </ol>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div></div>
      </section>

      <Modal
        // title="Vertically centered modal dialog"
        centered
        open={modal1Open}
        onOk={() => setModal1Open(false)}
        onCancel={() => setModal1Open(false)}
        footer={null}
      >
        <img
          height="auto"
          width="auto"
          src={HiIcon}
          alt="_blank"
          style={{ height: "5vh" }}
        />
        <p style={{ color: "black" }}>{whoToPlay}</p>
      </Modal>
      <Modal
        // title="Vertically centered modal dialog"
        centered
        open={modalOpen}
        onOk={() => setModalOpen(false)}
        onCancel={() => setModalOpen(false)}
        footer={null}
      >
        <p style={{ color: "black" }}>
          Well Done.{" "}
          <img
            height="auto"
            width="auto"
            src={CorrecIcon}
            alt="_blank"
            style={{ height: "5vh" }}
          />
        </p>
      </Modal>
      <Footer />
    </div>
  );
}

export default Puzzles;
