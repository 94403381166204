import axios from "axios";
const getPuzzles = async (turn) => {
  let apiResponse = await axios
    .get(`http://localhost:4000/getpuzzles/${turn}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response.data;
    });
  return apiResponse;
};
const selectPuzzles = async (turn, themes, rating) => {
  if (turn < 9) {
    let apiResponse = await axios
      .get(`http://localhost:4000/selectpuzzles/${turn}/${themes}/${rating}`)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err.response.data;
      });
    return apiResponse;
  }
};

export { getPuzzles, selectPuzzles };
