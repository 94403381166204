import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReCAPTCHA from "react-google-recaptcha";
import logofooter from "../../assets/images/chessversenewlogo.png";
import logoheader2x from "../../assets/images/chessversenewlogo.png";
import logodark from "../../assets/images/chessversenewlogo.png";
import logodark2x from "../../assets/images/chessversenewlogo.png";
import useInput from "../../customhooks/useInput";
import footercontactfetch from "../../services/footercontact";
import SendIcon from "../../assets/images/send.png";
const Footer = () => {
  const recaptcha = useRef();
  let email = useInput("");
  const accountList = [];
  const resourcesList = [
    {
      title: "Help & Support",
      link: "/help-center",
    },
  ];
  const companyList = [
    {
      title: "Contact Us",
      link: "/contact-01",
    },
  ];
  const socialList = [
    {
      icon: "fab fa-instagram",
      link: "https://www.instagram.com/playatchessverse/?utm_source=qr&igsh=MzNlNGNkZWQ4Mg%3D%3D",
    },
    {
      icon: "fab fa-facebook",
      link: "#",
    },
    {
      icon: "fab fa-youtube",
      link: "https://youtube.com/@thechessverse?si=gbPhuuT20yb0ZU2I-here",
    },
    {
      icon: "fab fa-twitter",
      link: "https://x.com/PLAYCHESSVERSE",
    },
   
  ];

  const [isVisible, setIsVisible] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    const toggleVisibility = () => {
      if (window.pageYOffset > 500) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", toggleVisibility);

    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);

  const handleClick = async (e) => {
    e.preventDefault();
    let emailRegex = new RegExp(
      /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/
    );
    if (!emailRegex.test(email.value)) {
      return toast("Email is not valid.");
    }
    const captchaValue = recaptcha.current.getValue();
    if (!captchaValue) {
      return toast("Please verify the reCAPTCHA!");
    }
    let data = await footercontactfetch(email.value);
    if (data == "mail sent") {
      toast(`🦄 ${data}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    } else {
      toast(`🦄 ${data}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };

  return (
    <div>
      <footer id="footer" className="footer-light-style clearfix bg-style">
        <div className="themesflat-container">
          <div className="row">
            <div className="col-lg-3 col-md-12 col-12">
              <div className="widget widget-logo">
                {/* <div className="logo-footer" id="logo-footer">
                  <Link to="/">
                    <img
                      height="auto"
                      width="auto"
                      className="logo-dark"
                      id="logo_footer"
                      src={logodark}
                      alt="nft-gaming"
                      style={{ height: "12vh" }}
                    />
                    <img
                      height="auto"
                      width="auto"
                      className="logo-light"
                      id="logo_footer"
                      src={logofooter}
                      alt="nft-gaming"
                      style={{ height: "12vh" }}
                    />
                  </Link>
                </div> */}
                {/* <p
                  className="sub-widget-logo"
                  style={{ fontFamily: "gangofthree" }}
                >
                  &copy; Chessverse 2023
                </p> */}
                {/* <p
                  className="sub-widget-logo"
                  style={{ fontFamily: "gangofthree" }}
                >
                  All rights reserved{" "}
                </p> */}
                <h6
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    padding: "1vh",
                  }}
                >

<Link to="/privacypolicy" style={{ textDecoration: "none" }}>
      <a style={{ fontFamily: "gangofthree" , marginLeft: "1vh" }}>
      PRIVACY POLICY
      </a>
    </Link>

                  {/* <a
                    // href="https://www.thechessverse.com/privacypolicy"
           href= "/privacypolicy"
                    className="sub-widget-logo"
                    style={{ fontFamily: "gangofthree" }}
                  >
                    PRIVACY POLICY
                  </a> */}
                </h6>
              </div>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-5 col-5">
              <div className="widget widget-menu fl-st-3">
                {/* <h5
                  className="title-widget"
                  style={{ fontFamily: "gangofthree" }}
                >
                  <a
                    href="https://www.thechessverse.com/privacypolicy"
                    className="sub-widget-logo"
                    style={{ fontFamily: "gangofthree" }}
                  >
                    PRIVACY POLICY
                  </a>
                </h5> */}
                {/* <ul>
                  {companyList.map((item, index) => (
                    <li key={index}>
                      <Link
                        to={item.link}
                        style={{ fontFamily: "gangofthree" }}
                      >
                        {item.title}
                      </Link>
                    </li>
                  ))}
                </ul> */}
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-7 col-12">
              <div className="widget widget-subcribe">
                <h5
                  className="title-widget"
                  style={{ fontFamily: "gangofthree" }}
                >
                  Register your interest
                </h5>
                <div className="form-subcribe">
                  <form
                    id="subscribe-form"
                    action="#"
                    method="GET"
                    acceptCharset="utf-8"
                    className="form-submit"
                  >
                    <input
                       style={{ fontFamily: 'Peppermint, sans-serif' }}
                   
                      name="email"
                      className="email"
                      
                      type="email"
                      placeholder="Enter Your Email"
                      required
                      value={email.value}
                      onChange={email.onChange}
                    />
                    <button
                      id="submit"
                      name="submit"
                      type="submit"
                      onClick={handleClick}
                    >
                      <i className="fab fa-send">
                        <img alt="send" src={SendIcon} style={{ height: "3vh" }} />
                      </i>
                    </button>
                  </form>
                  <div className="my-2">
                    <ReCAPTCHA
                      sitekey="6LepaGkoAAAAAIOGyQET3KHaMtHe75pBuPecvfe_"
                      ref={recaptcha}
                    />
                  </div>
                </div>
                <div className="widget-social style-1 mg-t32">
                  <ul>
                    {socialList.map((item, index) => (
                      <li key={index}>
                        <Link to={item.link}>
                          <i className={item.icon}></i>
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      {isVisible && <Link onClick={scrollToTop} to="#" id="scroll-top"></Link>}

      <div
        className="modal fade popup"
        id="popup_bid"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Footer;
