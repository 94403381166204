import React, { useRef } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReCAPTCHA from "react-google-recaptcha";
import emailjs from 'emailjs-com';
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import useInput from "../customhooks/useInput";

const Contact01 = () => {
  const recaptcha = useRef();
  const email = useInput("");
  const name = useInput("");
  const subject = useInput("");
  const message = useInput("");

  const handleSubmit = (e) => {
    e.preventDefault();

    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    const nameRegex = /^[A-Za-z]+$/;

    if (!nameRegex.test(name.value)) {
      return toast("Name is not valid.");
    }

    if (!emailRegex.test(email.value)) {
      return toast("Email is not valid.");
    }

    const captchaValue = recaptcha.current.getValue();
    if (!captchaValue) {
      return toast("Please verify the reCAPTCHA!");
    }

    const templateParams = {
      from_name: name.value,
      from_email: email.value,
      subject: subject.value,
      message: message.value
    };

   

    const acknowledgmentParams = {
      user_email: email.value,
      reply_to: 'chess@thechessverse.com',
      };

    emailjs.send('service_6i79y3f', 'template_w8s4fjk', templateParams, 'DYcM_1grnTzpaqcRq')
      .then((response) => {
        console.log('SUCCESS!', response.status, response.text);
        toast(`🦄 Mail sent successfully`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });

        // Send acknowledgment email
        emailjs.send('service_6i79y3f', 'template_lc86fmg', acknowledgmentParams, 'DYcM_1grnTzpaqcRq')
      })
      .then((response) => {
        console.log('Acknowledgment SUCCESS!', response.status, response.text);
        // toast(`🦄 Acknowledgment email sent successfully`, {
        //   position: "top-right",
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   theme: "dark",
        // });
      })
      .catch((err) => {
        console.error('FAILED...', err);
        // toast(`🦄 Mail sending failed: ${err.text}`, {
        //   position: "top-right",
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   theme: "dark",
        // });
      });
  };

  return (
    <div>
      <Header />
      <section className="flat-title-page inner">
        <div className="overlay"></div>
        <div className="themesflat-container">
          <div className="row">
            <div className="col-md-12">
              <div className="page-title-heading mg-bt-12">
                <h1 className="heading text-center" style={{ fontFamily: "gangofthree" }}>
                  Contact Us
                </h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="tf-contact tf-section">
        <div className="themesflat-container">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-12">
              <div className="box-feature-contact">
                <img height="auto" width="auto" src={"https://chessverse.b-cdn.net/monino1.webp"} alt="Chessverse" />
              </div>
            </div>
            <div className="col-lg-6 col-md=6 col-12">
              <h2 className="tf-title-heading style-2 mg-bt-12" style={{ fontFamily: "gangofthree" }}>
                Drop Us A Message
              </h2>
              <h5 className="sub-title style-1" style={{ fontFamily: "gangofthree" }}>
                Drop us a line using this groovy form. let the fun begin!" 😄📝🎉
              </h5>
              <div className="form-inner">
                <form id="contactform" className="form-submit" onSubmit={handleSubmit}>
                  <input
                    style={{ fontFamily: 'Peppermint, sans-serif' }}
                    id="name"
                    name="name"
                    tabIndex="1"
                    aria-required="true"
                    type="text"
                    placeholder="Your Full Name"
                    required
                    value={name.value}
                    onChange={name.onChange}
                  />
                  <input
                    style={{ fontFamily: 'Peppermint, sans-serif' }}
                    id="email"
                    name="email"
                    tabIndex="2"
                    aria-required="true"
                    type="email"
                    placeholder="Your Email Address"
                    required
                    value={email.value}
                    onChange={email.onChange}
                  />
                  <div className="row-form style-2" id="subject">
                    <input
                      style={{ fontFamily: 'Peppermint, sans-serif' }}
                      id="subject"
                      name="subject"
                      tabIndex="2"
                      aria-required="true"
                      type="text"
                      placeholder="Subject"
                      required
                      value={subject.value}
                      onChange={subject.onChange}
                    />
                    <i className="icon-fl-down"></i>
                  </div>
                  <textarea
                    style={{ fontFamily: 'Peppermint, sans-serif' }}
                    id="message"
                    name="message"
                    tabIndex="3"
                    aria-required="true"
                    required
                    placeholder="Message"
                    value={message.value}
                    onChange={message.onChange}
                  ></textarea>
                  <p style={{ fontFamily: 'Peppermint, sans-serif',fontSize: '12px' }}>
                    If you are under thirteen, please seek your parents permission before sharing your details.
                  </p>
                  <div className="mb-3">
                    <ReCAPTCHA
                      sitekey="6LepaGkoAAAAAIOGyQET3KHaMtHe75pBuPecvfe_"
                      ref={recaptcha}
                    />
                  </div>
                  <button
                    style={{ fontFamily: 'Peppermint, sans-serif' }}
                    className="submit"
                    type="submit"
                  >
                    Send message
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
      <ToastContainer />
    </div>
  );
};

export default Contact01;
