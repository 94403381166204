import React, { useRef, useState } from "react";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import heroSliderData from "../assets/fake-data/data-slider";
import Slider from "../components/slider/Slider";
import AdsComponent from "../components/AdsComponent/index.js";
import UpdateComponent from "../components/UpdatesBanner/index.js";
import CloseImg from "../assets/images/close.webp";
import planetData from "../assets/fake-data/data-live-auction";
import seedsData from "../assets/fake-data/seeds-data";
import LiveAuction from "../components/layouts/LiveAuction";
import "./Home01.css";
import ReactPlayer from "react-player";

import Carousel from "./Carousel";
import FacebookImage from "../assets/images/icon/facebook.webp";
import GmailImage from "../assets/images/logo/social.webp";
import TwitterImage from "../assets/images/logo/twitter.webp";
import YoutubeImage from "../assets/images/logo/youtube.webp";
import LinkedInImage from "../assets/images/logo/linkedin.webp";
import DiscordImage from "../assets/images/logo/discord.webp";
import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/scss/pagination";

const Home01 = () => {
  const ref = useRef(null);
  // let [show, setShow] = useState(false);
  // const handleClickAndroid = () => {
  //   window.location.href =
  //     "https://portal.testapp.io/apps/install/rQ9VP7zRqGe90";
  // };
  // const handleClickIOS = () => {
  //   window.location.href = "/coming-soon";
  // };
  // const handleClose = () => {
  //   setShow(true);
  // };
  return (
    <div className="home-1">
      <Header />
      
     <div className="slidercss" >
     <Slider data={heroSliderData} referingvalue={ref} />
     
     </div>
      
      <div className="bgdivparent">
      
      
        <div className="bgdiv">
        <div className="page-title-heading mg-bt-12">
          
                <h2
                  className="heading text-center"
                  style={{ fontFamily: "gangofthree" }}
                >
                  MAKE A MOVE <br></br>SAVE THE WORLD
                  <br></br>     

                </h2>
                <h3
                  className="heading text-center"
                  style={{ fontFamily: "gangofthree" }}
                >   
                  COMING SOON...
                  <br></br>     

                </h3>
              </div>
          
             
          <div className="divofcarousel">
            {/* <Carousel/> */}
            <ReactPlayer
              url="https://youtu.be/cY6hTXRDlSw"
              // playing={true}
              controls={true}
              width="auto"
              height="auto"
            />
          </div>
        </div>
      </div>
     
        <Footer />
      </div>
    
  );
};

export default Home01;



// https://chessverse.b-cdn.net/google-play-badge-en.png

// https://chessverse.b-cdn.net/apple-badge-en.svg


//https://chessverse.b-cdn.net/android4.png
