import emailjs from 'emailjs-com';

const footercontactfetch = async (email) => {
  const templateParams = {
    user_email: email,
    reply_to: 'chess@thechessverse.com',
  };

  const registerInterestParams = {
    from_name: 'register_interest',
    from_email: email.value,
    subject: 'register_interest',
    message: 'register_interest'
  };

  const sendAcknowledgementEmail = async (templateParams) => {
    try {
      await emailjs.send('service_6i79y3f', 'template_lc86fmg', templateParams, 'DYcM_1grnTzpaqcRq');
      console.log('SUCCESS! Ack email sent');
    } catch (err) {
      console.error('FAILED to send ack email', err);
    }
  };

  const sendContactEmail = async (registerInterestParams) => {
    try {
      await emailjs.send('service_6i79y3f', 'template_w8s4fjk', registerInterestParams  , 'DYcM_1grnTzpaqcRq');
      console.log('SUCCESS! Contact email sent');
    } catch (err) {
      console.error('FAILED to send contact email', err);
      return 'Failed to send contact email';
    }
  };

  try {
    const contactEmailResponse = await sendContactEmail(registerInterestParams);
    if (contactEmailResponse === 'Failed to send contact email') {
      return contactEmailResponse;
    }

    await sendAcknowledgementEmail(templateParams);
    return 'mail sent';
  } catch (err) {
    return 'Failed to send ema  il';
  }
};

export default footercontactfetch;
