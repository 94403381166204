import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import LazyLoad from 'react-lazyload';
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";

const sections = [
  {
    title: 'Games',
    cards: Array.from({ length: 16 }, (_, index) => ({
      title: `Game ${index + 1}`,
      imageUrl: `https://chessverse.b-cdn.net/snowy.webp`, // Use a placeholder image or your actual image URL
      link: `/games/${index + 1}`,
    })),
  },
  {
    title: 'Puzzles',
    cards: Array.from({ length: 16 }, (_, index) => ({
      title: `Puzzle ${index + 1}`,
      imageUrl: `https://howlerlife.com/wp-content/uploads/2021/02/f52b8-img_8482-768x1024-1.jpg`,
      link: `/puzzles/${index + 1}`,
    })),
  },
  {
    title: 'Fun',
    cards: Array.from({ length: 16 }, (_, index) => ({
      title: `Fun ${index + 1}`,
      imageUrl: `https://via.placeholder.com/300x200?text=Fun${index + 1}`,
      link: `/fun/${index + 1}`,
    })),
  },
  {
    title: 'Concepts',
    cards: Array.from({ length: 16 }, (_, index) => ({
      title: `Concept ${index + 1}`,
      imageUrl: `https://via.placeholder.com/300x200?text=Concept${index + 1}`,
      link: `/concepts/${index + 1}`,
    })),
  },
];

const cards = () => {
  return (
    <div>
      <Header />
      <Container>
        {sections.map((section, sectionIndex) => (
          <Section key={sectionIndex}>
            <SectionTitle>{section.title}</SectionTitle>
            <CardContainer>
              {section.cards.map((card, cardIndex) => (
                <Card key={cardIndex}>
                  <StyledLink to={card.link}>
                    <LazyLoad height={200} offset={100}>
                      <Image src={card.imageUrl} alt={card.title} />
                    </LazyLoad>
                    <Text>{card.title}</Text>
                  </StyledLink>
                </Card>
              ))}
            </CardContainer>
          </Section>
        ))}
      </Container>
      <Footer />
    </div>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: black; /* Set the background color to black */
  color: white; /* Set the default text color to white */
`;

const Section = styled.div`
  margin-bottom: 40px;
  margin-top: 80px;
`;

const SectionTitle = styled.h2`
  text-align: center;
  margin-bottom: 10px;
  font-family: 'Gang of Three', sans-serif;
  color: white; /* Ensure section titles are white */
`;

const CardContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  width: 100%;
  max-width: 1200px; /* Adjust max width as needed */
  margin: 0 auto;

  @media (max-width: 767px) {
    grid-template-columns: repeat(2, 1fr); /* 2x2 layout for mobile phones */
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    grid-template-columns: repeat(4, 1fr); /* 4x4 layout for tablets */
  }

  @media (min-width: 1024px) {
    grid-template-columns: repeat(4, 1fr); /* 4x4 layout for larger screens */
  }
`;

const Card = styled.div`
  position: relative;
  text-align: center;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;

  &:hover {
    transform: scale(1.05);
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: inherit;
  display: block;
`;

const Image = styled.img`
  width: 100%;
  height: auto;
  transition: transform 0.3s ease-in-out;

  &:hover {
    transform: scale(1.2);
  }
`;

const Text = styled.div`
  padding: 10px;
  background-color: #333; /* Darker background for the text container */
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  font-family: 'Gang of Three', sans-serif;
  color: white; /* Ensure text color is white */
`;

export default cards;
